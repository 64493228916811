<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span> {{ item }} </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="sales_office">Sales Office Code</vs-th
        ><vs-th sort-key="vendor_code">Vendor Code</vs-th
        ><vs-th sort-key="vendor_name">Vendor Name</vs-th
        ><vs-th sort-key="purchase_org_code">Purchase Org Code</vs-th
        ><vs-th sort-key="purchase_org_name">Purchase Org Name</vs-th
        ><vs-th sort-key="soa_number">SOA Number</vs-th
        ><vs-th sort-key="debit_note_number">Debit Note Number</vs-th
        ><vs-th sort-key="doc_ref_type">Doc Ref Type</vs-th
        ><vs-th sort-key="doc_status">Doc Status</vs-th
        ><vs-th sort-key="doc_ref_no">Doc Ref No</vs-th
        ><vs-th sort-key="debit_note_date">Debit Note Date</vs-th
        ><vs-th sort-key="posting_date">Posting Date</vs-th
        ><vs-th sort-key="notes">Notes</vs-th
        ><vs-th sort-key="total_amount">Total Amount</vs-th
        ><vs-th sort-key="debit_note_due_date">Debit Note Due Date</vs-th
        ><vs-th sort-key="assignment">Assignment</vs-th
        ><vs-th sort-key="reference_desc">Reference Desc</vs-th
        ><vs-th sort-key="cust_code">Cust Code</vs-th
        ><vs-th sort-key="cust_name">Cust Name</vs-th
        ><vs-th sort-key="balance_type">Balance Type</vs-th
        ><vs-th sort-key="sum_total_current">Sum Total Current</vs-th
        ><vs-th sort-key="due15lc">Due15LC</vs-th
        ><vs-th sort-key="due30lc">Due30LC</vs-th
        ><vs-th sort-key="due60lc">Due60LC</vs-th
        ><vs-th sort-key="due90lc">Due90LC</vs-th
        ><vs-th sort-key="due180lc">Due180LC</vs-th
        ><vs-th sort-key="due365lc">Due365LC</vs-th
        ><vs-th sort-key="due730lc">Due730LC</vs-th
        ><vs-th sort-key="due_more_than730lc">Due More Than730LC</vs-th
        ><vs-th sort-key="total_due">Total Due</vs-th
        ><vs-th sort-key="item">ITEM</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].sales_office">{{
            data[indextr].sales_office
          }}</vs-td
          ><vs-td :data="data[indextr].vendor_code">{{
            data[indextr].vendor_code
          }}</vs-td
          ><vs-td :data="data[indextr].vendor_name">{{
            data[indextr].vendor_name
          }}</vs-td
          ><vs-td :data="data[indextr].purchase_org_code">{{
            data[indextr].purchase_org_code
          }}</vs-td
          ><vs-td :data="data[indextr].purchase_org_name">{{
            data[indextr].purchase_org_name
          }}</vs-td
          ><vs-td :data="data[indextr].soa_number">{{
            data[indextr].soa_number
          }}</vs-td
          ><vs-td :data="data[indextr].debit_note_number">{{
            data[indextr].debit_note_number
          }}</vs-td
          ><vs-td :data="data[indextr].doc_ref_type">{{
            data[indextr].doc_ref_type
          }}</vs-td
          ><vs-td :data="data[indextr].doc_status">{{
            data[indextr].doc_status
          }}</vs-td
          ><vs-td :data="data[indextr].doc_ref_no">{{
            data[indextr].doc_ref_no
          }}</vs-td
          ><vs-td :data="data[indextr].debit_note_date">{{
            data[indextr].debit_note_date
          }}</vs-td
          ><vs-td :data="data[indextr].posting_date">{{
            data[indextr].posting_date
          }}</vs-td
          ><vs-td :data="data[indextr].notes">{{ data[indextr].notes }}</vs-td
          ><vs-td :data="data[indextr].total_amount">{{
            data[indextr].total_amount
          }}</vs-td
          ><vs-td :data="data[indextr].debit_note_due_date">{{
            data[indextr].debit_note_due_date
          }}</vs-td
          ><vs-td :data="data[indextr].assignment">{{
            data[indextr].assignment
          }}</vs-td
          ><vs-td :data="data[indextr].reference_desc">{{
            data[indextr].reference_desc
          }}</vs-td
          ><vs-td :data="data[indextr].cust_code">{{
            data[indextr].cust_code
          }}</vs-td
          ><vs-td :data="data[indextr].cust_name">{{
            data[indextr].cust_name
          }}</vs-td
          ><vs-td :data="data[indextr].balance_type">{{
            data[indextr].balance_type
          }}</vs-td
          ><vs-td :data="data[indextr].sum_total_current">{{
            formatPrice(data[indextr].sum_total_current)
          }}</vs-td
          ><vs-td :data="data[indextr].due15lc">{{
            formatPrice(data[indextr].due15lc)
          }}</vs-td
          ><vs-td :data="data[indextr].due30lc">{{
            formatPrice(data[indextr].due30lc)
          }}</vs-td
          ><vs-td :data="data[indextr].due60lc">{{
            formatPrice(data[indextr].due60lc)
          }}</vs-td
          ><vs-td :data="data[indextr].due90lc">{{
            formatPrice(data[indextr].due90lc)
          }}</vs-td
          ><vs-td :data="data[indextr].due180lc">{{
            formatPrice(data[indextr].due180lc)
          }}</vs-td
          ><vs-td :data="data[indextr].due365lc">{{
            formatPrice(data[indextr].due365lc)
          }}</vs-td
          ><vs-td :data="data[indextr].due730lc">{{
            formatPrice(data[indextr].due730lc)
          }}</vs-td
          ><vs-td :data="data[indextr].due_more_than730lc">{{
            formatPrice(data[indextr].due_more_than730lc)
          }}</vs-td
          ><vs-td :data="data[indextr].total_due">{{
            formatPrice(data[indextr].total_due)
          }}</vs-td
          ><vs-td :data="data[indextr].item">{{ data[indextr].item }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    startDepositDate: {
      type: Date,
    },
    endDepositDate: {
      type: Date,
    },
    startCollectionDate: {
      type: Date,
    },
    endCollectionDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    territoryNames: {
      type: Array,
    },
    ouIDs: {
      type: Array,
    },
    ouNames: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.reportDebitNote.tableData,
      total: (state) => state.reportDebitNote.total,
      totalPerPage: (state) => state.reportDebitNote.totalPerPage,
      totalPage: (state) => state.reportDebitNote.totalPage,
      totalSearch: (state) => state.reportDebitNote.totalSearch,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ getTableData: 'reportDebitNote/getDebitNoteReport' }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: '',
        order: 'territory_code',
        sort: 'desc',
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, 'All'],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == 'All' ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      console.log('get data');
      this.$vs.loading();
      this.getTableData({
        length: this.table.length,
        page: this.table.page,
        search: this.table.search == '' ? undefined : this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        // start_deposit_date:
        //   this.startDepositDate == null
        //     ? undefined
        //     : moment(this.startDepositDate).format('YYYY-MM-DD'),
        // end_deposit_date:
        //   this.endDepositDate == null
        //     ? undefined
        //     : moment(this.endDepositDate).format('YYYY-MM-DD'),
        // start_collection_date:
        //   this.startCollectionDate == null
        //     ? undefined
        //     : moment(this.startCollectionDate).format('YYYY-MM-DD'),
        // end_collection_date:
        //   this.endCollectionDate == null
        //     ? undefined
        //     : moment(this.endCollectionDate).format('YYYY-MM-DD'),
        // territory:
        //   this.territoryIDs.length == 0
        //     ? undefined
        //     : this.territoryIDs.join(','),
        // ou: this.ouIDs.length == 0 ? undefined : this.ouIDs.join(','),
      }).then((resp) => {
        this.$vs.loading.close();
        this.table.data = resp.records;
      });
    },
    setStartEnd() {
      let valStart =
        this.tableData.length * this.table.page - this.tableData.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.tableData.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (this.totalSearch < this.table.total && this.table.search != '') {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY');
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        'ARTRANS_REPORT_' +
        (this.territoryNames.length > 0
          ? this.territoryNames.join('-')
          : 'all') +
        '_';

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          'DOC_DATE_' +
          moment(this.startDocDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDocDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startPostingDate || this.endPostingDate) {
        'POSTING_DATE_' +
          moment(this.startPostingDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endPostingDate).format('YYYY-MM-DD') +
          '_';
      }

      if (this.startDueDate || this.endDueDate) {
        'DUE_DATE_' +
          moment(this.startDueDate).format('YYYY-MM-DD') +
          '_-_' +
          moment(this.endDueDate).format('YYYY-MM-DD') +
          '_';
      }

      fileTitle +=
        'OPEN_KEY_DATE_' + moment(this.openKeyDate).format('YYYY-MM-DD');

      this.$http
        .get(this.baseUrl + '/export', {
          params: {
            territory_ids: this.territoryIDs,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format('YYYY-MM-DD')
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format('YYYY-MM-DD')
              : null,
            start_doc_date: this.startDocDate
              ? moment(this.startDocDate).format('YYYY-MM-DD')
              : null,
            end_doc_date: this.endDocDate
              ? moment(this.endDocDate).format('YYYY-MM-DD')
              : null,
            start_due_date: this.startDueDate
              ? moment(this.startDueDate).format('YYYY-MM-DD')
              : null,
            end_due_date: this.endDueDate
              ? moment(this.endDueDate).format('YYYY-MM-DD')
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format('YYYY-MM-DD')
              : null,
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == 'error') {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          } else {
            this.$vs.notify({
              color: 'success',
              title: 'Processing',
              text: resp.message,
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle',
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    format(head, value) {
      if (typeof head['format'] === 'function') {
        var f = head['format'];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    detail() {
      this.getData();
    },
    startPostingDate() {
      this.getData();
    },
    endPostingDate() {
      this.getData();
    },
    startDocDate() {
      this.getData();
    },
    endDocDate() {
      this.getData();
    },
    startDueDate() {
      this.getData();
    },
    endDueDate() {
      this.getData();
    },
    openKeyDate() {
      this.getData();
    },
    territoryID() {
      this.getData();
    },
    draw() {
      this.getData();
    },
    tableData(val) {
      this.table.data = val;
    },
    total(val) {
      this.table.total = val;
    },
    totalPerPage(val) {
      this.table.totalPerPage = val;
    },
    totalPage(val) {
      this.table.totalPage = val;
    },
    totalSearch(val) {
      this.table.totalSearch = val;
    },
    'table.data'() {
      this.setStartEnd();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
